var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Provider, WalletUnlocked, Wallet, getMintedAssetId } from "fuels";
import { NFTContract } from "../../types/erc721";
import { Erc721V2 } from "../../types/erc721-v2";
import { Erc721V2Wl } from "../../types/erc721-v2-wl";
function numberTo64Hex(num) {
    return '0x' + num.toString(16).padStart(64, '0');
}
function setup(contractId, provider, wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        const _provider = yield Provider.create(provider);
        if (wallet && typeof wallet === "string") {
            const _provider = yield Provider.create(provider);
            const walletUnlocked = new WalletUnlocked(wallet, _provider);
            return new NFTContract(contractId, walletUnlocked);
        }
        else if (wallet && typeof wallet !== "string") {
            return new NFTContract(contractId, wallet);
        }
        return new NFTContract(contractId, _provider);
    });
}
function setupV2(contractId, provider, wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        const _provider = yield Provider.create(provider);
        if (wallet && typeof wallet === "string") {
            const _provider = yield Provider.create(provider);
            const walletUnlocked = new WalletUnlocked(wallet, _provider);
            return new Erc721V2(contractId, walletUnlocked);
        }
        else if (wallet && typeof wallet !== "string") {
            return new Erc721V2(contractId, wallet);
        }
        return new Erc721V2(contractId, _provider);
    });
}
function setupV2Wl(contractId, provider, wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        const _provider = yield Provider.create(provider);
        if (wallet && typeof wallet === "string") {
            const _provider = yield Provider.create(provider);
            const walletUnlocked = new WalletUnlocked(wallet, _provider);
            return new Erc721V2Wl(contractId, walletUnlocked);
        }
        else if (wallet && typeof wallet !== "string") {
            return new Erc721V2Wl(contractId, wallet);
        }
        return new Erc721V2Wl(contractId, _provider);
    });
}
export function constructor(contractId, provider, wallet, owner) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2(contractId, provider, wallet);
            const call = yield contract.functions
                .constructor({ Address: { bits: owner } })
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`ERC721: mint failed. Reason: ${err}`);
        }
    });
}
export function mint(contractId, provider, wallet, amount, subId, to) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const stringSubId = numberTo64Hex(subId);
            const _to = { Address: { bits: to } };
            const call = yield contract.functions
                .mint(_to, stringSubId, amount)
                .txParams({})
                .call();
            const { transactionResult, logs } = yield call.waitForResult();
            return { transactionResult, logs };
        }
        catch (err) {
            throw Error(`ERC721: mint failed. Reason: ${err}`);
        }
    });
}
export function bulkMintWithMulticall(contractId, provider, wallet, to, amount) {
    return __awaiter(this, void 0, void 0, function* () {
        let calls = [];
        const contract = yield setup(contractId, provider, wallet);
        const currentIndexBN = yield totalSupply(contractId, provider);
        const currentIndex = Number(currentIndexBN.value) === 0 ? 1 : Number(currentIndexBN.value);
        for (let i = currentIndex; i < (currentIndex + amount); i++) {
            const stringSubId = numberTo64Hex(i);
            const _to = { Address: { bits: to } };
            const mintCall = contract.functions
                .mint(_to, stringSubId, 1)
                .txParams({ variableOutputs: 1 });
            calls.push(mintCall);
        }
        if (calls.length === 0)
            return null;
        try {
            const call = yield contract.multiCall(calls)
                .txParams({ variableOutputs: amount })
                .call();
            const { transactionResult, logs } = yield call.waitForResult();
            return { transactionResult, logs };
        }
        catch (err) {
            throw Error(`ERC721: bulkMintWithMulticall failed. Reason: ${err}`);
        }
    });
}
export function balanceOf(assetId, owner, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const wallet = Wallet.fromAddress(owner, provider);
            const balance = yield wallet.getBalance(assetId);
            return { balance };
        }
        catch (err) {
            throw Error('ERC721: balanceOf failed');
        }
    });
}
export function totalSupply(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider);
            const { value } = yield contract.functions
                .total_assets()
                .get();
            return { value };
        }
        catch (err) {
            throw Error(`ERC721: totalSupply failed. Reason: ${err}`);
        }
    });
}
export function transfer(contractId, provider, wallet, to, tokenId, amount) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const _to = Wallet.fromAddress(to, provider);
            const subId = numberTo64Hex(tokenId);
            const assetId = getMintedAssetId(contractId, subId);
            const res = yield wallet.transfer(_to.address, amount, assetId, {});
            const txResult = yield res.wait();
            return txResult;
        }
        catch (err) {
            throw Error(`ERC721: transfer failed. Reason: ${err}`);
        }
    });
}
export function getMetadata(contractId, provider, wallet, tokenId) {
    return __awaiter(this, void 0, void 0, function* () {
        const zeroX = "0x";
        const fill0 = tokenId.toString().padStart(64, "0");
        const subId = fill0.padStart(66, zeroX);
        const assetId = getMintedAssetId(contractId, subId);
        try {
            const contract = yield setup(contractId, provider, wallet);
            const { value } = yield contract.functions
                .metadata({ bits: assetId }, "metadata")
                .simulate();
            return { value };
        }
        catch (err) {
            throw Error(`ERC721: getMetadata failed. Reason: ${err}`);
        }
    });
}
/*** v2 functions ****/
export function mintV2(contractId, provider, wallet, subId, to, price, baseAssetId) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2(contractId, provider, wallet);
            const stringSubId = numberTo64Hex(subId);
            const coin = { amount: price, assetId: baseAssetId };
            const _to = { Address: { bits: to } };
            const call = yield contract.functions
                .mint(_to, stringSubId, subId, 1)
                .txParams({ variableOutputs: 3 })
                .callParams({ forward: coin })
                .call();
            const { transactionResult, logs } = yield call.waitForResult();
            return { transactionResult, logs };
        }
        catch (err) {
            throw Error(`ERC721: mintV2 failed. Reason: ${err}`);
        }
    });
}
export function bulkMintV2(contractId, provider, wallet, to, tokenIds, pricePerNft, baseAssetId) {
    return __awaiter(this, void 0, void 0, function* () {
        let calls = [];
        const contract = yield setupV2(contractId, provider, wallet);
        for (const tokenId of tokenIds) {
            const stringSubId = numberTo64Hex(tokenId);
            const coin = { amount: pricePerNft, assetId: baseAssetId };
            const _to = { Address: { bits: to } };
            const mintCall = contract.functions
                .mint(_to, stringSubId, tokenId, 1)
                .txParams({ variableOutputs: 3 })
                .callParams({ forward: coin });
            calls.push(mintCall);
        }
        if (calls.length === 0)
            return null;
        try {
            const call = yield contract.multiCall(calls)
                .txParams({ variableOutputs: (calls.length * 3) })
                .call();
            const { transactionResult, logs } = yield call.waitForResult();
            return { transactionResult, logs };
        }
        catch (err) {
            throw Error(`ERC721: bulkMintV2 failed. Reason: ${err}`);
        }
    });
}
export function setBaseUri(contractId, provider, wallet, baseUri) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2(contractId, provider, wallet);
            const call = yield contract.functions
                .set_base_uri(baseUri)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`ERC721: setBaseUri failed. Reason: ${err}`);
        }
    });
}
export function setBaseName(contractId, provider, wallet, baseName) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2Wl(contractId, provider, wallet);
            const call = yield contract.functions
                .set_base_name(baseName)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`ERC721: setBaseName failed. Reason: ${err}`);
        }
    });
}
export function setBaseSymbol(contractId, provider, wallet, baseSymbol) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2Wl(contractId, provider, wallet);
            const call = yield contract.functions
                .set_base_symbol(baseSymbol)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`ERC721: setBaseSymbol failed. Reason: ${err}`);
        }
    });
}
export function setPrice(contractId, provider, wallet, price) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2(contractId, provider, wallet);
            const call = yield contract.functions
                .set_price(price)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`ERC721: setPrice failed. Reason: ${err}`);
        }
    });
}
export function setMaxMintPerWallet(contractId, provider, wallet, maxMintPerWallet) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2(contractId, provider, wallet);
            const call = yield contract.functions
                .set_max_mint_per_wallet(maxMintPerWallet)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`ERC721: setMaxMintPerWallet failed. Reason: ${err}`);
        }
    });
}
export function setWithdrawAddress(contractId, provider, wallet, withdrawAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2(contractId, provider, wallet);
            const call = yield contract.functions
                .set_withdraw_address({ Address: { bits: withdrawAddress } })
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`ERC721: setWithdrawAddress failed. Reason: ${err}`);
        }
    });
}
export function getWithdrawAddress(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2(contractId, provider);
            const { value } = yield contract.functions
                .get_withdraw_address()
                .get();
            return { value };
        }
        catch (err) {
            throw Error(`ERC721: setWithdrawAddress failed. Reason: ${err}`);
        }
    });
}
export function getPrice(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2(contractId, provider);
            const { value } = yield contract.functions
                .get_price()
                .get();
            return { value };
        }
        catch (err) {
            throw Error(`ERC721: setWithdrawAddress failed. Reason: ${err}`);
        }
    });
}
export function getMaxMintPerWallet(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2(contractId, provider);
            const { value } = yield contract.functions
                .get_max_mint_per_wallet()
                .get();
            return { value };
        }
        catch (err) {
            throw Error(`ERC721: setWithdrawAddress failed. Reason: ${err}`);
        }
    });
}
export function getBaseUri(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2(contractId, provider);
            const { value } = yield contract.functions
                .get_base_uri()
                .get();
            return { value };
        }
        catch (err) {
            throw Error(`ERC721: setWithdrawAddress failed. Reason: ${err}`);
        }
    });
}
export function transferOwnership(contractId, provider, wallet, newOwner) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2(contractId, provider, wallet);
            const call = yield contract.functions
                .transfer_ownership({ Address: { bits: newOwner } })
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`ERC721: transferOwnership failed. Reason: ${err}`);
        }
    });
}
/*** v2-wl functions ****/
export function bulkMintV2Wl(contractId, provider, wallet, to, tokenIds, pricePerNft, baseAssetId) {
    return __awaiter(this, void 0, void 0, function* () {
        let calls = [];
        const contract = yield setupV2Wl(contractId, provider, wallet);
        for (const tokenId of tokenIds) {
            const stringSubId = numberTo64Hex(tokenId);
            const coin = { amount: pricePerNft, assetId: baseAssetId };
            const _to = { Address: { bits: to } };
            const mintCall = contract.functions
                .mint(_to, stringSubId, tokenId, 1)
                .txParams({ variableOutputs: 3 })
                .callParams({ forward: coin });
            calls.push(mintCall);
        }
        if (calls.length === 0)
            return null;
        try {
            const call = yield contract.multiCall(calls)
                .txParams({ variableOutputs: (calls.length * 3) })
                .call();
            const { transactionResult, logs } = yield call.waitForResult();
            return { transactionResult, logs };
        }
        catch (err) {
            throw Error(`ERC721: bulkMintV2Wl failed. Reason: ${err}`);
        }
    });
}
// export async function bulkMintV2Wl2 (
//     contractId: string,
//     provider: string,
//     wallet: string | WalletLocked,
//     to: string[],
//     tokenIds: number[],
//     pricePerNft: BigNumberish,
//     baseAssetId: string
// ) {
//     let calls: FunctionInvocationScope<any[], any>[] = [];
//     const contract = await setupV2Wl(contractId, provider, wallet);
//     for (const [index, address] of to.entries()) {
//         const tokenId = tokenIds[index]
//         const stringSubId = numberTo64Hex(tokenId);
//         const coin: CoinQuantityLike = { amount: pricePerNft, assetId: baseAssetId };
//         const _to: IdentityInput = { Address: { bits: address } };
//         const mintCall = contract.functions
//             .mint(_to, stringSubId, tokenId, 1)
//             .txParams({ variableOutputs: 3 })
//             .callParams({ forward: coin })
//         calls.push(mintCall);
//     }
//     if (calls.length === 0) return null;
//     try {
//         const call = await contract.multiCall(calls)
//             .txParams({variableOutputs: (calls.length * 3)})
//             .call();
//         const { transactionResult, logs } = await call.waitForResult()
//         return { transactionResult, logs };
//     } catch(err: any) {
//         throw Error(`ERC721: bulkMintV2Wl failed. Reason: ${err}`);
//     }
// }
export function setPublicPrice(contractId, provider, wallet, price) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2Wl(contractId, provider, wallet);
            const call = yield contract.functions
                .set_public_price(price)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`ERC721: setPublicPrice failed. Reason: ${err}`);
        }
    });
}
export function setWhitelistPrice(contractId, provider, wallet, price) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2Wl(contractId, provider, wallet);
            const call = yield contract.functions
                .set_whitelist_price(price)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`ERC721: setWhitelistPrice failed. Reason: ${err}`);
        }
    });
}
export function setPublicMaxMintPerWallet(contractId, provider, wallet, maxMintPerWallet) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2Wl(contractId, provider, wallet);
            const call = yield contract.functions
                .set_public_max_mint_per_wallet(maxMintPerWallet)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`ERC721: setPublicMaxMintPerWallet failed. Reason: ${err}`);
        }
    });
}
export function setWhitelistMaxMintPerWallet(contractId, provider, wallet, maxMintPerWallet) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2Wl(contractId, provider, wallet);
            const call = yield contract.functions
                .set_whitelist_max_mint_per_wallet(maxMintPerWallet)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`ERC721: setWhitelistMaxMintPerWallet failed. Reason: ${err}`);
        }
    });
}
export function setWhitelistedAddresses(contractId, provider, wallet, addresses) {
    return __awaiter(this, void 0, void 0, function* () {
        const identites = [];
        for (const address of addresses) {
            const identity = { Address: { bits: address } };
            identites.push(identity);
        }
        try {
            const contract = yield setupV2Wl(contractId, provider, wallet);
            const call = yield contract.functions
                .set_whitelisted_addresses(identites)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`ERC721: setWhitelistedAddresses failed. Reason: ${err}`);
        }
    });
}
export function bulkSetWhitelistedAddresses(contractId, provider, wallet, addressChunks) {
    return __awaiter(this, void 0, void 0, function* () {
        let calls = [];
        const contract = yield setupV2Wl(contractId, provider, wallet);
        for (const addresses of addressChunks) {
            const identites = [];
            for (const address of addresses) {
                const identity = { Address: { bits: address } };
                identites.push(identity);
            }
            const call = contract.functions
                .set_whitelisted_addresses(identites)
                .txParams({});
            calls.push(call);
        }
        try {
            const call = yield contract.multiCall(calls)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`ERC721: setWhitelistedAddresses failed. Reason: ${err}`);
        }
    });
}
export function setToggleMintPhase(contractId, provider, wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2Wl(contractId, provider, wallet);
            const call = yield contract.functions
                .toggle_mint_phase()
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`ERC721: setToggleMintPhase failed. Reason: ${err}`);
        }
    });
}
export function isWhitelistedAddress(contractId, provider, address) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const identity = { Address: { bits: address } };
            const contract = yield setupV2Wl(contractId, provider);
            const { value } = yield contract.functions
                .is_whitelisted(identity)
                .get();
            return { value };
        }
        catch (err) {
            throw Error(`ERC721: isWhitelistedAddress failed. Reason: ${err}`);
        }
    });
}
export function isWhitelistedPhase(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2Wl(contractId, provider);
            const { value } = yield contract.functions
                .is_whitelist_phase()
                .get();
            return { value };
        }
        catch (err) {
            throw Error(`ERC721: isWhitelistedPhase failed. Reason: ${err}`);
        }
    });
}
export function getPublicPrice(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2Wl(contractId, provider);
            const { value } = yield contract.functions
                .get_public_price()
                .get();
            return { value };
        }
        catch (err) {
            throw Error(`ERC721: isWhitelistedPhase failed. Reason: ${err}`);
        }
    });
}
export function getWhitelistPrice(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2Wl(contractId, provider);
            const { value } = yield contract.functions
                .get_whitelist_price()
                .get();
            return { value };
        }
        catch (err) {
            throw Error(`ERC721: isWhitelistedPhase failed. Reason: ${err}`);
        }
    });
}
export function getPublicMaxMintPerWallet(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2Wl(contractId, provider);
            const { value } = yield contract.functions
                .get_public_max_mint_per_wallet()
                .get();
            return { value };
        }
        catch (err) {
            throw Error(`ERC721: isWhitelistedPhase failed. Reason: ${err}`);
        }
    });
}
export function getWhitelistMaxMintPerWallet(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setupV2Wl(contractId, provider);
            const { value } = yield contract.functions
                .get_whitelist_max_mint_per_wallet()
                .get();
            return { value };
        }
        catch (err) {
            throw Error(`ERC721: isWhitelistedPhase failed. Reason: ${err}`);
        }
    });
}
