/* Autogenerated file. Do not edit manually. */
/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-imports */
/*
  Fuels version: 0.97.0
*/
import { Contract, Interface } from "fuels";
export var AccessErrorInput;
(function (AccessErrorInput) {
    AccessErrorInput["NotOwner"] = "NotOwner";
})(AccessErrorInput || (AccessErrorInput = {}));
;
export var AccessErrorOutput;
(function (AccessErrorOutput) {
    AccessErrorOutput["NotOwner"] = "NotOwner";
})(AccessErrorOutput || (AccessErrorOutput = {}));
;
export var BurnErrorInput;
(function (BurnErrorInput) {
    BurnErrorInput["NotEnoughCoins"] = "NotEnoughCoins";
    BurnErrorInput["ZeroAmount"] = "ZeroAmount";
})(BurnErrorInput || (BurnErrorInput = {}));
;
export var BurnErrorOutput;
(function (BurnErrorOutput) {
    BurnErrorOutput["NotEnoughCoins"] = "NotEnoughCoins";
    BurnErrorOutput["ZeroAmount"] = "ZeroAmount";
})(BurnErrorOutput || (BurnErrorOutput = {}));
;
export var InitializationErrorInput;
(function (InitializationErrorInput) {
    InitializationErrorInput["CannotReinitialized"] = "CannotReinitialized";
})(InitializationErrorInput || (InitializationErrorInput = {}));
;
export var InitializationErrorOutput;
(function (InitializationErrorOutput) {
    InitializationErrorOutput["CannotReinitialized"] = "CannotReinitialized";
})(InitializationErrorOutput || (InitializationErrorOutput = {}));
;
export var MintErrorInput;
(function (MintErrorInput) {
    MintErrorInput["CannotMintMoreThanOneNFTWithSubId"] = "CannotMintMoreThanOneNFTWithSubId";
    MintErrorInput["MaxNFTsMinted"] = "MaxNFTsMinted";
    MintErrorInput["NFTAlreadyMinted"] = "NFTAlreadyMinted";
    MintErrorInput["InsufficientEth"] = "InsufficientEth";
    MintErrorInput["AssetIdMismatched"] = "AssetIdMismatched";
    MintErrorInput["ExceedsMaxMintLimit"] = "ExceedsMaxMintLimit";
    MintErrorInput["WithdrawAddressNotSet"] = "WithdrawAddressNotSet";
    MintErrorInput["AddressIsNotWhitelisted"] = "AddressIsNotWhitelisted";
    MintErrorInput["ExceedsGiveawayMaxMintLimit"] = "ExceedsGiveawayMaxMintLimit";
})(MintErrorInput || (MintErrorInput = {}));
;
export var MintErrorOutput;
(function (MintErrorOutput) {
    MintErrorOutput["CannotMintMoreThanOneNFTWithSubId"] = "CannotMintMoreThanOneNFTWithSubId";
    MintErrorOutput["MaxNFTsMinted"] = "MaxNFTsMinted";
    MintErrorOutput["NFTAlreadyMinted"] = "NFTAlreadyMinted";
    MintErrorOutput["InsufficientEth"] = "InsufficientEth";
    MintErrorOutput["AssetIdMismatched"] = "AssetIdMismatched";
    MintErrorOutput["ExceedsMaxMintLimit"] = "ExceedsMaxMintLimit";
    MintErrorOutput["WithdrawAddressNotSet"] = "WithdrawAddressNotSet";
    MintErrorOutput["AddressIsNotWhitelisted"] = "AddressIsNotWhitelisted";
    MintErrorOutput["ExceedsGiveawayMaxMintLimit"] = "ExceedsGiveawayMaxMintLimit";
})(MintErrorOutput || (MintErrorOutput = {}));
;
(function (MintErrorInput) {
    MintErrorInput["ZeroAmount"] = "ZeroAmount";
})(MintErrorInput || (MintErrorInput = {}));
;
(function (MintErrorOutput) {
    MintErrorOutput["ZeroAmount"] = "ZeroAmount";
})(MintErrorOutput || (MintErrorOutput = {}));
;
export var PauseErrorInput;
(function (PauseErrorInput) {
    PauseErrorInput["Paused"] = "Paused";
    PauseErrorInput["NotPaused"] = "NotPaused";
})(PauseErrorInput || (PauseErrorInput = {}));
;
export var PauseErrorOutput;
(function (PauseErrorOutput) {
    PauseErrorOutput["Paused"] = "Paused";
    PauseErrorOutput["NotPaused"] = "NotPaused";
})(PauseErrorOutput || (PauseErrorOutput = {}));
;
export var SetErrorInput;
(function (SetErrorInput) {
    SetErrorInput["ValueAlreadySet"] = "ValueAlreadySet";
})(SetErrorInput || (SetErrorInput = {}));
;
export var SetErrorOutput;
(function (SetErrorOutput) {
    SetErrorOutput["ValueAlreadySet"] = "ValueAlreadySet";
})(SetErrorOutput || (SetErrorOutput = {}));
;
export var SetMetadataErrorInput;
(function (SetMetadataErrorInput) {
    SetMetadataErrorInput["EmptyString"] = "EmptyString";
    SetMetadataErrorInput["EmptyBytes"] = "EmptyBytes";
})(SetMetadataErrorInput || (SetMetadataErrorInput = {}));
;
export var SetMetadataErrorOutput;
(function (SetMetadataErrorOutput) {
    SetMetadataErrorOutput["EmptyString"] = "EmptyString";
    SetMetadataErrorOutput["EmptyBytes"] = "EmptyBytes";
})(SetMetadataErrorOutput || (SetMetadataErrorOutput = {}));
;
const abi = {
    "programType": "contract",
    "specVersion": "1",
    "encodingVersion": "1",
    "concreteTypes": [
        {
            "type": "()",
            "concreteTypeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
            "type": "b256",
            "concreteTypeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b"
        },
        {
            "type": "bool",
            "concreteTypeId": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903"
        },
        {
            "type": "enum errors::MintError",
            "concreteTypeId": "ee89c439b5472cab716006d7c677d16e15301e6c82cfce12cebfa57919f537de",
            "metadataTypeId": 0
        },
        {
            "type": "enum errors::SetError",
            "concreteTypeId": "bf6597cf3d56a5e47a920520e275ccd481a27e7c988ea6af6f253170d5374c5a",
            "metadataTypeId": 1
        },
        {
            "type": "enum standards::src5::AccessError",
            "concreteTypeId": "3f702ea3351c9c1ece2b84048006c8034a24cbc2bad2e740d0412b4172951d3d",
            "metadataTypeId": 2
        },
        {
            "type": "enum standards::src5::State",
            "concreteTypeId": "192bc7098e2fe60635a9918afb563e4e5419d386da2bdbf0d716b4bc8549802c",
            "metadataTypeId": 3
        },
        {
            "type": "enum standards::src7::Metadata",
            "concreteTypeId": "f44b531974c6c04e17e66ab54e9868d230b9a24b3710b184399c363f0190180d",
            "metadataTypeId": 4
        },
        {
            "type": "enum std::identity::Identity",
            "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
            "metadataTypeId": 5
        },
        {
            "type": "enum std::option::Option<enum standards::src7::Metadata>",
            "concreteTypeId": "fe93748eeb5d91a422fcea06e1b374216ad4ac0b2db01be0a6316af7f90dfa4f",
            "metadataTypeId": 6,
            "typeArguments": [
                "f44b531974c6c04e17e66ab54e9868d230b9a24b3710b184399c363f0190180d"
            ]
        },
        {
            "type": "enum std::option::Option<enum std::identity::Identity>",
            "concreteTypeId": "253aea1197e8005518365bd24c8bc31f73a434fac0f7350e57696edfdd4850c2",
            "metadataTypeId": 6,
            "typeArguments": [
                "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
            ]
        },
        {
            "type": "enum std::option::Option<struct std::string::String>",
            "concreteTypeId": "7c06d929390a9aeeb8ffccf8173ac0d101a9976d99dda01cce74541a81e75ac0",
            "metadataTypeId": 6,
            "typeArguments": [
                "9a7f1d3e963c10e0a4ea70a8e20a4813d1dc5682e28f74cb102ae50d32f7f98c"
            ]
        },
        {
            "type": "enum std::option::Option<u64>",
            "concreteTypeId": "d852149004cc9ec0bbe7dc4e37bffea1d41469b759512b6136f2e865a4c06e7d",
            "metadataTypeId": 6,
            "typeArguments": [
                "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
            ]
        },
        {
            "type": "enum std::option::Option<u8>",
            "concreteTypeId": "2da102c46c7263beeed95818cd7bee801716ba8303dddafdcd0f6c9efda4a0f1",
            "metadataTypeId": 6,
            "typeArguments": [
                "c89951a24c6ca28c13fd1cfdc646b2b656d69e61a92b91023be7eb58eb914b6b"
            ]
        },
        {
            "type": "enum sway_libs::asset::errors::BurnError",
            "concreteTypeId": "3acdc2adac8e0589c5864525e0edc9dc61a9571a4d09c3c57b58ea76d33f4b46",
            "metadataTypeId": 7
        },
        {
            "type": "enum sway_libs::asset::errors::MintError",
            "concreteTypeId": "dff9dfec998a49b40f1c4b09567400f0e712aaf939c08f7d07bc5c63116e1084",
            "metadataTypeId": 8
        },
        {
            "type": "enum sway_libs::asset::errors::SetMetadataError",
            "concreteTypeId": "c6c09c148c1a1341c7ab81697b3545cc695fa67668a169cddc59790a9a0b6b44",
            "metadataTypeId": 9
        },
        {
            "type": "enum sway_libs::ownership::errors::InitializationError",
            "concreteTypeId": "1dfe7feadc1d9667a4351761230f948744068a090fe91b1bc6763a90ed5d3893",
            "metadataTypeId": 10
        },
        {
            "type": "enum sway_libs::pausable::errors::PauseError",
            "concreteTypeId": "8b3afcadf894415a10b09fc3717487e33802c8ffbb030edafe84ca4a71b280bc",
            "metadataTypeId": 11
        },
        {
            "type": "struct events::MintEvent",
            "concreteTypeId": "8cfe38d1ba0d97380df81da6075bed5def90f5bde5e54647772963da050fde12",
            "metadataTypeId": 14
        },
        {
            "type": "struct standards::src20::SetNameEvent",
            "concreteTypeId": "6ce295b0fb4c1c15e8ed1cfa4babda47d8a04940a5266a3229e12243a2e37c2c",
            "metadataTypeId": 15
        },
        {
            "type": "struct standards::src20::SetSymbolEvent",
            "concreteTypeId": "a8a4b78066c51a50da6349bd395fe1c67e774d75c1db2c5c22288a432d7a363d",
            "metadataTypeId": 16
        },
        {
            "type": "struct standards::src20::TotalSupplyEvent",
            "concreteTypeId": "f255d5cc2114d1b6bc34bef4c28d4b60caccffd9a672ed16b79ea217e1c4a8a3",
            "metadataTypeId": 17
        },
        {
            "type": "struct standards::src7::SetMetadataEvent",
            "concreteTypeId": "f1b1cc90b68559aa4bb5cc58201ebb5c5402ed3aa28927140761e8ff7dcd3ab8",
            "metadataTypeId": 18
        },
        {
            "type": "struct std::address::Address",
            "concreteTypeId": "f597b637c3b0f588fb8d7086c6f4735caa3122b85f0423b82e489f9bb58e2308",
            "metadataTypeId": 19
        },
        {
            "type": "struct std::asset_id::AssetId",
            "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974",
            "metadataTypeId": 20
        },
        {
            "type": "struct std::string::String",
            "concreteTypeId": "9a7f1d3e963c10e0a4ea70a8e20a4813d1dc5682e28f74cb102ae50d32f7f98c",
            "metadataTypeId": 24
        },
        {
            "type": "struct std::vec::Vec<enum std::identity::Identity>",
            "concreteTypeId": "2f79033d0d3729398611309f48578b56cf5162ba85e50f4d8fb79c9d9d1abc7b",
            "metadataTypeId": 26,
            "typeArguments": [
                "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
            ]
        },
        {
            "type": "struct sway_libs::ownership::events::OwnershipRenounced",
            "concreteTypeId": "43c4fa7b3297401afbf300127e59ea913e5c8f0c7ae69abbec789ab0bb872bed",
            "metadataTypeId": 27
        },
        {
            "type": "struct sway_libs::ownership::events::OwnershipSet",
            "concreteTypeId": "e1ef35033ea9d2956f17c3292dea4a46ce7d61fdf37bbebe03b7b965073f43b5",
            "metadataTypeId": 28
        },
        {
            "type": "struct sway_libs::ownership::events::OwnershipTransferred",
            "concreteTypeId": "b3fffbcb3158d7c010c31b194b60fb7857adb4ad61bdcf4b8b42958951d9f308",
            "metadataTypeId": 29
        },
        {
            "type": "u64",
            "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
            "type": "u8",
            "concreteTypeId": "c89951a24c6ca28c13fd1cfdc646b2b656d69e61a92b91023be7eb58eb914b6b"
        }
    ],
    "metadataTypes": [
        {
            "type": "enum errors::MintError",
            "metadataTypeId": 0,
            "components": [
                {
                    "name": "CannotMintMoreThanOneNFTWithSubId",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "MaxNFTsMinted",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "NFTAlreadyMinted",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "InsufficientEth",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "AssetIdMismatched",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "ExceedsMaxMintLimit",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "WithdrawAddressNotSet",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "AddressIsNotWhitelisted",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "ExceedsGiveawayMaxMintLimit",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                }
            ]
        },
        {
            "type": "enum errors::SetError",
            "metadataTypeId": 1,
            "components": [
                {
                    "name": "ValueAlreadySet",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                }
            ]
        },
        {
            "type": "enum standards::src5::AccessError",
            "metadataTypeId": 2,
            "components": [
                {
                    "name": "NotOwner",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                }
            ]
        },
        {
            "type": "enum standards::src5::State",
            "metadataTypeId": 3,
            "components": [
                {
                    "name": "Uninitialized",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "Initialized",
                    "typeId": 5
                },
                {
                    "name": "Revoked",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                }
            ]
        },
        {
            "type": "enum standards::src7::Metadata",
            "metadataTypeId": 4,
            "components": [
                {
                    "name": "B256",
                    "typeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b"
                },
                {
                    "name": "Bytes",
                    "typeId": 21
                },
                {
                    "name": "Int",
                    "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                },
                {
                    "name": "String",
                    "typeId": 24
                }
            ]
        },
        {
            "type": "enum std::identity::Identity",
            "metadataTypeId": 5,
            "components": [
                {
                    "name": "Address",
                    "typeId": 19
                },
                {
                    "name": "ContractId",
                    "typeId": 23
                }
            ]
        },
        {
            "type": "enum std::option::Option",
            "metadataTypeId": 6,
            "components": [
                {
                    "name": "None",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "Some",
                    "typeId": 12
                }
            ],
            "typeParameters": [
                12
            ]
        },
        {
            "type": "enum sway_libs::asset::errors::BurnError",
            "metadataTypeId": 7,
            "components": [
                {
                    "name": "NotEnoughCoins",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "ZeroAmount",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                }
            ]
        },
        {
            "type": "enum sway_libs::asset::errors::MintError",
            "metadataTypeId": 8,
            "components": [
                {
                    "name": "ZeroAmount",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                }
            ]
        },
        {
            "type": "enum sway_libs::asset::errors::SetMetadataError",
            "metadataTypeId": 9,
            "components": [
                {
                    "name": "EmptyString",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "EmptyBytes",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                }
            ]
        },
        {
            "type": "enum sway_libs::ownership::errors::InitializationError",
            "metadataTypeId": 10,
            "components": [
                {
                    "name": "CannotReinitialized",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                }
            ]
        },
        {
            "type": "enum sway_libs::pausable::errors::PauseError",
            "metadataTypeId": 11,
            "components": [
                {
                    "name": "Paused",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "NotPaused",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                }
            ]
        },
        {
            "type": "generic T",
            "metadataTypeId": 12
        },
        {
            "type": "raw untyped ptr",
            "metadataTypeId": 13
        },
        {
            "type": "struct events::MintEvent",
            "metadataTypeId": 14,
            "components": [
                {
                    "name": "recipient",
                    "typeId": 5
                },
                {
                    "name": "token_id",
                    "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                },
                {
                    "name": "sub_id",
                    "typeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b"
                }
            ]
        },
        {
            "type": "struct standards::src20::SetNameEvent",
            "metadataTypeId": 15,
            "components": [
                {
                    "name": "asset",
                    "typeId": 20
                },
                {
                    "name": "name",
                    "typeId": 6,
                    "typeArguments": [
                        {
                            "name": "",
                            "typeId": 24
                        }
                    ]
                },
                {
                    "name": "sender",
                    "typeId": 5
                }
            ]
        },
        {
            "type": "struct standards::src20::SetSymbolEvent",
            "metadataTypeId": 16,
            "components": [
                {
                    "name": "asset",
                    "typeId": 20
                },
                {
                    "name": "symbol",
                    "typeId": 6,
                    "typeArguments": [
                        {
                            "name": "",
                            "typeId": 24
                        }
                    ]
                },
                {
                    "name": "sender",
                    "typeId": 5
                }
            ]
        },
        {
            "type": "struct standards::src20::TotalSupplyEvent",
            "metadataTypeId": 17,
            "components": [
                {
                    "name": "asset",
                    "typeId": 20
                },
                {
                    "name": "supply",
                    "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                },
                {
                    "name": "sender",
                    "typeId": 5
                }
            ]
        },
        {
            "type": "struct standards::src7::SetMetadataEvent",
            "metadataTypeId": 18,
            "components": [
                {
                    "name": "asset",
                    "typeId": 20
                },
                {
                    "name": "metadata",
                    "typeId": 6,
                    "typeArguments": [
                        {
                            "name": "",
                            "typeId": 4
                        }
                    ]
                },
                {
                    "name": "key",
                    "typeId": 24
                },
                {
                    "name": "sender",
                    "typeId": 5
                }
            ]
        },
        {
            "type": "struct std::address::Address",
            "metadataTypeId": 19,
            "components": [
                {
                    "name": "bits",
                    "typeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b"
                }
            ]
        },
        {
            "type": "struct std::asset_id::AssetId",
            "metadataTypeId": 20,
            "components": [
                {
                    "name": "bits",
                    "typeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b"
                }
            ]
        },
        {
            "type": "struct std::bytes::Bytes",
            "metadataTypeId": 21,
            "components": [
                {
                    "name": "buf",
                    "typeId": 22
                },
                {
                    "name": "len",
                    "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                }
            ]
        },
        {
            "type": "struct std::bytes::RawBytes",
            "metadataTypeId": 22,
            "components": [
                {
                    "name": "ptr",
                    "typeId": 13
                },
                {
                    "name": "cap",
                    "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                }
            ]
        },
        {
            "type": "struct std::contract_id::ContractId",
            "metadataTypeId": 23,
            "components": [
                {
                    "name": "bits",
                    "typeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b"
                }
            ]
        },
        {
            "type": "struct std::string::String",
            "metadataTypeId": 24,
            "components": [
                {
                    "name": "bytes",
                    "typeId": 21
                }
            ]
        },
        {
            "type": "struct std::vec::RawVec",
            "metadataTypeId": 25,
            "components": [
                {
                    "name": "ptr",
                    "typeId": 13
                },
                {
                    "name": "cap",
                    "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                }
            ],
            "typeParameters": [
                12
            ]
        },
        {
            "type": "struct std::vec::Vec",
            "metadataTypeId": 26,
            "components": [
                {
                    "name": "buf",
                    "typeId": 25,
                    "typeArguments": [
                        {
                            "name": "",
                            "typeId": 12
                        }
                    ]
                },
                {
                    "name": "len",
                    "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                }
            ],
            "typeParameters": [
                12
            ]
        },
        {
            "type": "struct sway_libs::ownership::events::OwnershipRenounced",
            "metadataTypeId": 27,
            "components": [
                {
                    "name": "previous_owner",
                    "typeId": 5
                }
            ]
        },
        {
            "type": "struct sway_libs::ownership::events::OwnershipSet",
            "metadataTypeId": 28,
            "components": [
                {
                    "name": "new_owner",
                    "typeId": 5
                }
            ]
        },
        {
            "type": "struct sway_libs::ownership::events::OwnershipTransferred",
            "metadataTypeId": 29,
            "components": [
                {
                    "name": "new_owner",
                    "typeId": 5
                },
                {
                    "name": "previous_owner",
                    "typeId": 5
                }
            ]
        }
    ],
    "functions": [
        {
            "inputs": [
                {
                    "name": "_asset",
                    "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
                }
            ],
            "name": "decimals",
            "output": "2da102c46c7263beeed95818cd7bee801716ba8303dddafdcd0f6c9efda4a0f1",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "asset",
                    "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
                }
            ],
            "name": "name",
            "output": "7c06d929390a9aeeb8ffccf8173ac0d101a9976d99dda01cce74541a81e75ac0",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "asset",
                    "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
                }
            ],
            "name": "symbol",
            "output": "7c06d929390a9aeeb8ffccf8173ac0d101a9976d99dda01cce74541a81e75ac0",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "total_assets",
            "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "asset",
                    "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
                }
            ],
            "name": "total_supply",
            "output": "d852149004cc9ec0bbe7dc4e37bffea1d41469b759512b6136f2e865a4c06e7d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "sub_id",
                    "concreteTypeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b"
                },
                {
                    "name": "amount",
                    "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                }
            ],
            "name": "burn",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "payable",
                    "arguments": []
                },
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "recipient",
                    "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
                },
                {
                    "name": "sub_id",
                    "concreteTypeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b"
                },
                {
                    "name": "token_id",
                    "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                },
                {
                    "name": "amount",
                    "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                }
            ],
            "name": "mint",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "payable",
                    "arguments": []
                },
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "asset",
                    "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
                },
                {
                    "name": "key",
                    "concreteTypeId": "9a7f1d3e963c10e0a4ea70a8e20a4813d1dc5682e28f74cb102ae50d32f7f98c"
                }
            ],
            "name": "metadata",
            "output": "fe93748eeb5d91a422fcea06e1b374216ad4ac0b2db01be0a6316af7f90dfa4f",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "owner",
            "output": "192bc7098e2fe60635a9918afb563e4e5419d386da2bdbf0d716b4bc8549802c",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "_asset",
                    "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
                },
                {
                    "name": "_decimals",
                    "concreteTypeId": "c89951a24c6ca28c13fd1cfdc646b2b656d69e61a92b91023be7eb58eb914b6b"
                }
            ],
            "name": "set_decimals",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "asset",
                    "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
                },
                {
                    "name": "name",
                    "concreteTypeId": "9a7f1d3e963c10e0a4ea70a8e20a4813d1dc5682e28f74cb102ae50d32f7f98c"
                }
            ],
            "name": "set_name",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "asset",
                    "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
                },
                {
                    "name": "symbol",
                    "concreteTypeId": "9a7f1d3e963c10e0a4ea70a8e20a4813d1dc5682e28f74cb102ae50d32f7f98c"
                }
            ],
            "name": "set_symbol",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "is_paused",
            "output": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "pause",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "unpause",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "owner",
                    "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
                }
            ],
            "name": "constructor",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "get_base_uri",
            "output": "9a7f1d3e963c10e0a4ea70a8e20a4813d1dc5682e28f74cb102ae50d32f7f98c",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "base_uri",
                    "concreteTypeId": "9a7f1d3e963c10e0a4ea70a8e20a4813d1dc5682e28f74cb102ae50d32f7f98c"
                }
            ],
            "name": "set_base_uri",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "get_public_max_mint_per_wallet",
            "output": "d852149004cc9ec0bbe7dc4e37bffea1d41469b759512b6136f2e865a4c06e7d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "get_public_price",
            "output": "d852149004cc9ec0bbe7dc4e37bffea1d41469b759512b6136f2e865a4c06e7d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "get_whitelist_max_mint_per_wallet",
            "output": "d852149004cc9ec0bbe7dc4e37bffea1d41469b759512b6136f2e865a4c06e7d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "get_whitelist_price",
            "output": "d852149004cc9ec0bbe7dc4e37bffea1d41469b759512b6136f2e865a4c06e7d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "get_withdraw_address",
            "output": "253aea1197e8005518365bd24c8bc31f73a434fac0f7350e57696edfdd4850c2",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "is_whitelist_phase",
            "output": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "value",
                    "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
                }
            ],
            "name": "is_whitelisted",
            "output": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "name",
                    "concreteTypeId": "9a7f1d3e963c10e0a4ea70a8e20a4813d1dc5682e28f74cb102ae50d32f7f98c"
                }
            ],
            "name": "set_base_name",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "symbol",
                    "concreteTypeId": "9a7f1d3e963c10e0a4ea70a8e20a4813d1dc5682e28f74cb102ae50d32f7f98c"
                }
            ],
            "name": "set_base_symbol",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "value",
                    "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                }
            ],
            "name": "set_public_max_mint_per_wallet",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "value",
                    "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                }
            ],
            "name": "set_public_price",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "value",
                    "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                }
            ],
            "name": "set_whitelist_max_mint_per_wallet",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "value",
                    "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                }
            ],
            "name": "set_whitelist_price",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "value",
                    "concreteTypeId": "2f79033d0d3729398611309f48578b56cf5162ba85e50f4d8fb79c9d9d1abc7b"
                }
            ],
            "name": "set_whitelisted_addresses",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "value",
                    "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
                }
            ],
            "name": "set_withdraw_address",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "toggle_mint_phase",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "renounce_ownership",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "new_owner",
                    "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
                }
            ],
            "name": "transfer_ownership",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        }
    ],
    "loggedTypes": [
        {
            "logId": "10032608944051208538",
            "concreteTypeId": "8b3afcadf894415a10b09fc3717487e33802c8ffbb030edafe84ca4a71b280bc"
        },
        {
            "logId": "4237256875605624201",
            "concreteTypeId": "3acdc2adac8e0589c5864525e0edc9dc61a9571a4d09c3c57b58ea76d33f4b46"
        },
        {
            "logId": "17462098202904023478",
            "concreteTypeId": "f255d5cc2114d1b6bc34bef4c28d4b60caccffd9a672ed16b79ea217e1c4a8a3"
        },
        {
            "logId": "17188485204969729195",
            "concreteTypeId": "ee89c439b5472cab716006d7c677d16e15301e6c82cfce12cebfa57919f537de"
        },
        {
            "logId": "13791596350235125220",
            "concreteTypeId": "bf6597cf3d56a5e47a920520e275ccd481a27e7c988ea6af6f253170d5374c5a"
        },
        {
            "logId": "17415926155927968170",
            "concreteTypeId": "f1b1cc90b68559aa4bb5cc58201ebb5c5402ed3aa28927140761e8ff7dcd3ab8"
        },
        {
            "logId": "14321618427101975361",
            "concreteTypeId": "c6c09c148c1a1341c7ab81697b3545cc695fa67668a169cddc59790a9a0b6b44"
        },
        {
            "logId": "7845998088195677205",
            "concreteTypeId": "6ce295b0fb4c1c15e8ed1cfa4babda47d8a04940a5266a3229e12243a2e37c2c"
        },
        {
            "logId": "12152039456660331088",
            "concreteTypeId": "a8a4b78066c51a50da6349bd395fe1c67e774d75c1db2c5c22288a432d7a363d"
        },
        {
            "logId": "16139176946940135860",
            "concreteTypeId": "dff9dfec998a49b40f1c4b09567400f0e712aaf939c08f7d07bc5c63116e1084"
        },
        {
            "logId": "10159620282815190840",
            "concreteTypeId": "8cfe38d1ba0d97380df81da6075bed5def90f5bde5e54647772963da050fde12"
        },
        {
            "logId": "4571204900286667806",
            "concreteTypeId": "3f702ea3351c9c1ece2b84048006c8034a24cbc2bad2e740d0412b4172951d3d"
        },
        {
            "logId": "2161305517876418151",
            "concreteTypeId": "1dfe7feadc1d9667a4351761230f948744068a090fe91b1bc6763a90ed5d3893"
        },
        {
            "logId": "16280289466020123285",
            "concreteTypeId": "e1ef35033ea9d2956f17c3292dea4a46ce7d61fdf37bbebe03b7b965073f43b5"
        },
        {
            "logId": "4883303303013154842",
            "concreteTypeId": "43c4fa7b3297401afbf300127e59ea913e5c8f0c7ae69abbec789ab0bb872bed"
        },
        {
            "logId": "12970362301975156672",
            "concreteTypeId": "b3fffbcb3158d7c010c31b194b60fb7857adb4ad61bdcf4b8b42958951d9f308"
        }
    ],
    "messagesTypes": [],
    "configurables": [
        {
            "name": "MAX_SUPPLY",
            "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
            "offset": 83640
        },
        {
            "name": "DROP_FEE",
            "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
            "offset": 83592
        },
        {
            "name": "DROP_FEE_RECIPIENT",
            "concreteTypeId": "f597b637c3b0f588fb8d7086c6f4735caa3122b85f0423b82e489f9bb58e2308",
            "offset": 83600
        },
        {
            "name": "GIVEAWAY_MAX_SUPPLY",
            "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
            "offset": 83632
        }
    ]
};
const storageSlots = [
    {
        "key": "0681b4a41300dbc61f8f1cb363d735119bb96da71ce764267f0ec047a4ed0b20",
        "value": "0000000000000000000000000000000000000000000000000000000000000000"
    },
    {
        "key": "37abf9fc9cc09fbef8d94f352f1e40075488834327e4de3651ce3b12a71df579",
        "value": "0000000000000000000000000000000000000000000000000000000000000000"
    },
    {
        "key": "8959bb681c2b442d4fb16a89fdf8c0687f5797421cc102ade4af74053043c8c4",
        "value": "0000000000000000000000000000000000000000000000000000000000000000"
    },
    {
        "key": "8959bb681c2b442d4fb16a89fdf8c0687f5797421cc102ade4af74053043c8c5",
        "value": "0000000000000000000000000000000000000000000000000000000000000000"
    },
    {
        "key": "93b67ee4f0f76b71456fb4385c86aec15689e1ce5f6f6ac63b71716afa052998",
        "value": "0000000000000000000000000000000000000000000000000000000000000000"
    },
    {
        "key": "9e65fa9050aa56abec5c75af6e867c8d299981ce66eb937d3f63cab6ca15a4fa",
        "value": "0000000000000000000000000000000000000000000000000000000000000000"
    },
    {
        "key": "cd8ec32afa1a4ed9f49a47cb30a0df05c88d075f6cca979bdb85a743ed533894",
        "value": "0000000000000000000000000000000000000000000000000000000000000000"
    },
    {
        "key": "cefef4818140ca07f2cdbd3963b0883e5f7a9b770947f1efcd1c14896e24912a",
        "value": "0000000000000000000000000000000000000000000000000000000000000000"
    },
    {
        "key": "e9fd1712811b8d666269f80a04b8794604b1efd1ae1b3bc3685cc8aaf4d62d4c",
        "value": "0000000000000000000000000000000000000000000000000000000000000000"
    }
];
export class Erc721V2WlInterface extends Interface {
    constructor() {
        super(abi);
    }
}
export class Erc721V2Wl extends Contract {
    constructor(id, accountOrProvider) {
        super(id, abi, accountOrProvider);
    }
}
Erc721V2Wl.abi = abi;
Erc721V2Wl.storageSlots = storageSlots;
